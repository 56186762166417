<template>
  <v-app>
    <v-app-bar app v-show="isAuthenticated">
      <v-container fluid>
        <v-row align="center">
          <v-col
                class="d-flex align-center"
                xl="7"
                lg="7"
                md="7"
                sm="12"
                cols="12">
                <div class="d-flex align-center flex-wrap gap-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                         <v-btn 
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="popWord"
                            :disabled="!canPopWord"> 
                            <v-icon left class="mr-2">
                              mdi-undo
                            </v-icon>
                            <span class="d-none d-md-block">
                              Отменить
                            </span>
                          </v-btn>
                      </template>
                      <span>Убирает последнее добавленное слово из комбинации из таблицы</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn 
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="clearPhrase"
                          :disabled="!canClearPhrase"> 
                          <v-icon left class="mr-2">
                            mdi-delete
                          </v-icon>
                          <span class="d-none d-md-block">
                            Очистить
                          </span>
                        </v-btn>
                      </template>
                      <span>Полностью убирает все слова из комбинации из таблицы</span>
                    </v-tooltip>
                    
                    <v-select
                        class="mx-2 my-0 align-self-center"
                        v-model="sortMode"
                        :items="sortModes"
                        item-text="caption"
                        label="Сортировка строк"
                        persistent-hint
                        return-object
                        single-line
                        dense
                        hide-details
                        style="max-width: 300px; min-width: 200px"
                        ></v-select>

                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn 
                            class="mr-2"
                            depressed
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            @click="checkPhrase"
                            :disabled="!canCheckPhrase">
                          <v-icon left class="mr-2">
                            mdi-checkbox-marked-circle
                          </v-icon>
                          Проверить
                        </v-btn>
                      </template>
                      <span>Проверить комбинацию</span>
                    </v-tooltip>

                    <v-menu
                      bottom
                      left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          v-bind="attrs"
                          color="primary"
                          class="d-md-none"
                          v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                           <v-switch
                            v-model="$vuetify.theme.dark"
                            label="Темная тема"
                          ></v-switch>
                        </v-list-item>
                        <v-list-item 
                            @click="logout">
                          <v-list-item-icon>
                            <v-icon left>
                              mdi-logout
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Выйти
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                </div>
          </v-col>
          <v-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            cols="12"
            class="d-none d-md-flex align-center justify-end">
              <v-switch
                class="ma-0 pa-0 mr-4"
                v-model="$vuetify.theme.dark"
                inset
                label="Темная тема"
                hide-details
              ></v-switch>
              <v-tooltip left>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    depressed
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout">
                    <v-icon left class="mr-2">
                      mdi-logout
                    </v-icon>
                    Выйти
                  </v-btn>
                </template>
                <span>Выйти из приложения</span>
              </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container fluid v-if="isAuthenticated">
        <v-row no-gutters>

          <v-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            cols="12">
            <IncompleteMnemonicsTable 
              :mnemonicManager="mnemonicManager"
              :urlBuilder="urlBuilder"
              :snackbarService="snackbarService"
              :sortMode="sortMode"/>
          </v-col>

          <v-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            cols="12"
            class="pb-0 mt-sm-10 mt-md-0">
                <CheckRequests 
                    :mnemonicManager="mnemonicManager"
                    :mnemonicCheckRequestsService="mnemonicCheckRequestsService"/>  
          </v-col>

        </v-row>
      
      </v-container>
      <v-container fluid>
          <v-row>
            <v-snackbar v-model="snackbarService.showSnackbar">
              {{ this.snackbarService.snackbarText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbarService.showSnackbar = false"
                >
                  Закрыть
                </v-btn>
              </template>
          </v-snackbar>
        </v-row>
      </v-container>
      <v-dialog class="d-flex align-content-center align-center justify-center" fluid v-model="dialog" persistent max-width="400px" min-width="280px">
          <v-card>
              <v-card-title class="primary">
                BWKR
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container fluid> 
                  <v-row>
                    <v-text-field
                      label="Email"
                      @keyup.enter="login"
                      v-model="userEmail">
                      <v-icon
                        slot="prepend"
                        color="primary"
                      >
                        mdi-email
                      </v-icon>
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      label="Пароль"
                      type="password"
                      @keyup.enter="login"
                      v-model="userPassword">
                      <v-icon
                        slot="prepend"
                        color="primary"
                      >
                        mdi-lock
                      </v-icon>
                    </v-text-field>
                  </v-row>
                  <v-row align="center" justify="end">

                    <v-btn
                      tile
                      color="primary"
                      :loading="isAuthenticating"
                      @click="login"
                      :disabled="!canLogin">
                      <v-icon 
                        left>
                        mdi-login
                      </v-icon>
                      Вход
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card-text>
          </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>

import MnemonicManager from './core/MnemonicManager.js';
import CheckRequests from './components/CheckRequests.vue'
import IncompleteMnemonicsTable from './components/IncompleteMnemonicsTable.vue'
import UrlBuilder from './core/UrlBuilder.js'
import MnemonicCheckRequest from './core/MnemonicCheckRequest.js'
import MnemonicCheckRequestsService from './core/MnemonicCheckRequestsService.js'
import SnackbarService from './core/SnackbarService.js'
import SortMode from './core/SortMode.js'
import SortModes from './core/SortModes.js'

import axios from "axios"

const jwtTokenName = 'bwkr_jwt_token'

export default {
  name: 'App',

  components: {
    CheckRequests,
    IncompleteMnemonicsTable,
  },

  data: function () {
    return { 
      dialog: true,
      mnemonicManager: MnemonicManager,
      urlBuilder: UrlBuilder,
      mnemonicCheckRequestsService: MnemonicCheckRequestsService,
      snackbarService: SnackbarService,
      showSnackbar: false,
      snackbarText: '',
      isAuthenticated: false,
      isAuthenticating: false,
      userEmail: '',
      userPassword: '',
      sortModes: [],
      sortMode: SortMode
    }
  },

  computed: {

    canPopWord: function() {
      return this.mnemonicManager.canPopWord();
    },

    canClearPhrase: function() {
      return this.mnemonicManager.canClearPhrase();
    },

    canCheckPhrase: function() {
      return this.mnemonicManager.canGetPhrase();
    },

    canLogin: function() {
      return this.userEmail != null && this.userPassword != null && this.userEmail.length >= 1 && this.userPassword.length >= 1;
    },

  },

  methods: {
    
    popWord() {
      this.mnemonicManager.popWord();
    },

    clearPhrase() {
      this.mnemonicManager.clearPhrase();
    },

    async checkPhrase() {
      if (!this.mnemonicManager.canGetPhrase()) return;

      let phrase = this.mnemonicManager.getPhrase();

      try
      {
        const url = this.urlBuilder.buildUrlForAddRequest();
        var response = await axios.post(url, phrase);

        if (response.status != 200) {
            throw "Не удается подключиться к серверу";
        }
        
        if (!response.data.isSuccess)
        {
            console.log(response.data.errors);
            throw "Упс... Что-то пошло не так. Попробуйте позже.";
        }

        const requestId = response.data.body;
        console.log('Added new request with RequestId=%s', requestId);

        console.log('Fetching request with RequestId=%s...', requestId);
        const fetchUrl = this.urlBuilder.buildUrlForGetRequest(requestId);

        response = await axios.get(fetchUrl);
        if (response.status != 200) {
            throw "Не удается подключиться к серверу";
        }
        
        if (!response.data.isSuccess)
        {
            console.log(response.data.errors);

            if (response.data.errors.length > 0) {
              throw response.data.errors[0].description;
            }
            throw "Упс... Что-то пошло не так. Попробуйте позже.";
        }

        this.addRequest(response.data.body, true);
        console.log('Fetched request with RequestId=%s...', requestId);
        this.snackbarService.showMessage('Выполняется проверка...');
      }
      catch (error) {
        console.log(error);
        this.snackbarService.showMessage(error);
      }
    },

    addRequest(r, addToBeginning) {
      let request = new MnemonicCheckRequest(
            r.id,
            r.name,
            r.state,
            r.indexes,
            r.completedMnemonicsCount,
            r.validMnemonicsCount,
            r.validMnemonicsWithActivityCount,
            r.allWalletsChecked
          );

      this.mnemonicCheckRequestsService.addRequest(request, addToBeginning)
    },
    
    startAutoRefreshForNotification() {
      console.log('Started running timer for completed requests auto refreshing...');
      if (!this.timer) {
          this.timer = setInterval( async () => {
            try {
              const url = this.urlBuilder.buildUrlForGetRequestToNotify();

              const response = await axios.get(url);
  
              if (response.status != 200) {
                  throw "Не удается подключиться к серверу";
              }
              
              if (!response.data.isSuccess)
              {
                  console.log(response.data.errors);
                  throw "Упс... Не удалось выполнить проверку статуса запросов. Попробуйте позже.";
              }
      
              const data = response.data.body;
              if (data === null) {
                return;
              }

              console.log("Has request to notify with RequestId=%s (state=%s, valid mnemonics with activity = %s)", data.id, data.state, data.validMnemonicsWithActivityCount);

              if (data.state === 3){
                if (data.validMnemonicsWithActivityCount > 0) {
                  this.snackbarService.showMessage('🎉 Ура! Запрос с Id='+data.id+' удачный, тут есть активные кошельки');
                }
                else {
                  this.snackbarService.showMessage('👎 Нет активных кошельков для запроса с Id='+data.id);
                }
              }
              else
              {
                this.snackbarService.showMessage('Не удалось выполнить проверку запроса с Id=' + data.id);
              }
            }   
            catch (e) {
              this.snackbarService.showMessage('Непредвиденная ошибка при проверке статуса запроса');
              console.log(e);
            }         
          }, 2100);
          console.log('Completed running timer for completed request auto refreshing...');
      }
      else {
          console.log('Auto refreshing completed requests has been already started');
      }
    },

    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    async login() {
      if (!this.canLogin) return;

      this.isAuthenticating = true;
      try
      {
        console.log(`Authenticating user with Email=${this.userEmail}...`)
        const url = this.urlBuilder.buildUrlForLogin();
        var response = await axios.post(url, {
          Email: this.userEmail,
          Password: this.userPassword 
        });

        if (response.status != 200) {
            throw "Не удается подключиться к серверу";
        }
        
        if (!response.data.isSuccess)
        {
            console.log(response.data.errors);

            if (response.data.errors.length > 0) {
              throw response.data.errors[0].description;
            }
            throw "Упс... Что-то пошло не так. Попробуйте позже.";
        }

        console.log(response.data.body);


        localStorage.setItem(jwtTokenName, response.data.body);

        this.userPassword = '';
        this.isAuthenticated = true;
        console.log('Authenticated user');

        this.initMainForm(response.data.body);
      }
      catch (error) {
        console.log(error);
        this.snackbarService.showMessage(error);
      }
      finally {
        this.isAuthenticating = false;
      }
    },

    initMainForm(jwtToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;

      this.mnemonicCheckRequestsService.startAutoRefreshRequests();
      this.startAutoRefreshForNotification();

      this.dialog = false;
    },

    logout() {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.isAuthenticated = false;
      this.dialog = true;
      this.userPassword = '';
      localStorage.removeItem(jwtTokenName);
      this.$emit('logout');
      this.mnemonicCheckRequestsService.clearData();

    }
  },

  async created() {
   this.snackbarService = new SnackbarService();
   this.mnemonicManager = new MnemonicManager(this.snackbarService);
   console.log('NODE_ENV=%s', process.env.NODE_ENV);
   console.log('API_Url=%s', process.env.VUE_APP_API_URL);
   this.urlBuilder = new UrlBuilder(process.env.VUE_APP_API_URL);
   this.mnemonicCheckRequestsService = new MnemonicCheckRequestsService(this.urlBuilder, this.snackbarService);
  },

  mounted() {
    let jwtToken = localStorage.getItem(jwtTokenName);
    if (jwtToken === null) {
      this.isAuthenticated = false;
    }
    else
    {
      this.userPassword = '';
      this.isAuthenticating = false;
      this.isAuthenticated = true;
      this.initMainForm(jwtToken);
    }

    let t = new SortModes();
    this.sortModes = new Array(
      new SortMode(t.Default),
      new SortMode(t.CodePerGroupAsc),
      new SortMode(t.CodePerGroupDesc),
      new SortMode(t.NamePerGroupAlphabeticallyAsc),
      new SortMode(t.NamePerGroupAlphabeticallyDesc),
    );
    this.sortMode = this.sortModes[0];
  }
};
</script>
